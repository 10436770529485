<template>
  <div
    v-loading.fullscreen.lock="fullscreenLoading"
    class="loginBody"
    :style="{ height: `${screenHeight}px` }"
  >
    <!-- logo -->
    <div v-if="!isLandscape" class="logo flex level-between vertical-center">
      <img class="icon-logo" :src="logo">
      <div
        v-if="!isMobile"
        class="flex vertical-center level-center icon-item explain"
        @click="onJumpDownApp"
      >
        <img src="@/assets/download/icon_logo_white.png" alt="">
        <div>医生趣学术直播助手</div>
      </div>
    </div>
    <template v-if="!isMobile">
      <!-- 轮播图 -->
      <swiper ref="mySwiper" class="swiper-reset" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in slideList" :key="index">
          <img :src="item.bgImage" alt="" class="bg-image">
          <div class="slide-box">
            <div class="text-content">
              <div class="text-title">{{ item.title }}</div>
              <div class="text-desc">{{ item.desc }}</div>
              <img :src="item.navImage" alt="">
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </template>
    <!-- 登录内容 -->
    <div v-loading="loading" class="flex flex-column" :class="loginClass">
      <div class="tab-list flex level-equal vertical-center flex-shrink">
        <div class="active-bar" :style="activeBarStyle" />
        <div
          v-for="item in tabList"
          :ref="item.value"
          :key="item.value"
          class="tab-item pointer"
          :class="{ active: item.value === tabKey }"
          @click="handleTabClick(item.value)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 扫码登录 -->
      <div
        v-show="tabKey === 'scan' && !isMobile"
        class="scan-box flex flex-column vertical-center flex-box"
      >
        <div id="login_container" class="scan-image flex level-center" />
        <div class="flex level-center vertical-center scan-icon-box">
          <div class="flex vertical-center scan-icon-item">
            <img src="@/assets/user/icon_fast.png" alt="" class="scan-icon">
            <div>更便捷</div>
          </div>
          <div class="flex vertical-center">
            <img src="@/assets/user/icon_safe.png" alt="" class="scan-icon">
            <div>更安全</div>
          </div>
        </div>
        <div class="color-b6">有疑问请联系客服:13758257847</div>
      </div>
      <!-- 账号登录 -->
      <div v-show="tabKey === 'password'" class="password-box flex-box">
        <el-form
          ref="rulePasswordForm"
          :model="rulePasswordForm"
          :rules="passwordRules"
          label-width="100px"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <el-form-item label="账号" prop="account">
            <el-input v-model="rulePasswordForm.account" />
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="rulePasswordForm.password" type="password" />
          </el-form-item>
          <el-form-item
            prop="remember"
            class="remember-level"
            :style="{ 'margin-bottom': isLandscape ? '0px' : '15px' }"
          >
            <el-checkbox
              v-model="rulePasswordForm.remember"
              label="记住账号和密码"
              name="remember"
            />
            <span class="pointer" @click="onJumpForget">忘记登录密码</span>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('rulePasswordForm')">登录</el-button>
          </el-form-item>
          <div class="color-b6 text-ct">有疑问请联系客服:13758257847</div>
        </el-form>
      </div>
      <!-- 验证码登录 -->
      <div v-show="tabKey === 'sms'" class="sms-box flex-box">
        <input type="text" autocomplete="off" class="display-input">
        <input type="password" autocomplete="new-password" class="display-input">
        <el-form
          ref="ruleSMSForm"
          :model="ruleSMSForm"
          :rules="smsRules"
          label-width="100px"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="ruleSMSForm.mobile"
              maxlength="11"
              @input="(e) => testNumber(e, 'mobile')"
            />
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input
              v-model="ruleSMSForm.code"
              autocomplete="new-password"
              @input="(e) => testNumber(e, 'code')"
            />
            <div
              v-if="countDownTime == 0"
              class="send-sms-btn color-primary pointer"
              :class="{ disable: !isPhoneNumber && isSend }"
              @click="sendSms"
            >
              {{ isSend ? "发送中" : "获取验证码" }}
            </div>
            <div v-else class="send-sms-btn disable">{{ countDownTime }}s重新获取</div>
          </el-form-item>
          <div v-show="showVoice" class="sms-voice-btn color-primary pointer" @click="sendVoice">
            收不到验证码？试试语音验证
          </div>
          <el-form-item :style="{ 'padding-top': isLandscape ? '10px' : '60px' }">
            <el-button type="primary" @click="submitForm('ruleSMSForm')">登录</el-button>
          </el-form-item>
          <div class="color-b6 text-ct">有疑问请联系客服:13758257847</div>
        </el-form>
      </div>
    </div>
    <!-- 扫码登录失败弹窗提醒 -->
    <el-dialog
      :visible.sync="scanDialogVisible"
      width="30%"
      :center="true"
      :show-close="false"
      top="30vh"
      class="dialog-box"
    >
      <div class="flex level-center">
        <img src="@/assets/common/icon_failed.png" alt="">
      </div>
      <div class="text-ct margin-t25">{{ scanDialogMsg }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeScanErrorModal">确定</el-button>
      </span>
    </el-dialog>
    <!-- 账号登录失败弹窗提醒 -->
    <el-dialog
      :visible.sync="passwordDialogVisible"
      width="30%"
      :center="true"
      :show-close="false"
      top="30vh"
      class="dialog-box"
    >
      <div class="flex level-center">
        <img src="@/assets/common/icon_failed.png" alt="">
      </div>
      <div class="text-ct margin-t25">{{ passwordErrorModalMsg }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closePasswordErrorModal">我知道了</el-button>
      </span>
    </el-dialog>
    <!-- 选择登录账号 -->
    <SelectFactoryAccount
      :visible="showFactoryModal"
      :person-token="personToken"
      @onSubmit="onCheckAccount"
    />
    <!-- 忘记密码弹窗 -->
    <el-dialog
      :visible.sync="forgetDialogVisible"
      width="30%"
      :center="true"
      :show-close="false"
      top="30vh"
      class="dialog-box"
    >
      <div class="flex level-center">
        <img src="@/assets/common/icon_failed.png" alt="">
      </div>
      <div class="text-ct margin-t25">{{ forgetErrorModalMsg }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeForgetErrorModal">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { setPersonInfo } from '@/utils/auth'
import { initTCaptcha, cryptoEncrypt } from '@/utils/util'
import SelectFactoryAccount from '@/bizComponents/SelectFactoryAccount'
import { isMobile, isLandscape } from '@/utils/util.js'

export default {
  name: 'UserLogin',
  components: {
    SelectFactoryAccount
  },
  data() {
    return {
      personToken: '',
      slideList: [
        {
          title: '企业级低成本学术合规化',
          desc: '打造数字化证据链，赋能拓展更多线上学术推广场景',
          navImage: require('@/assets/user/login_slide_blue.png'),
          bgImage: require('@/assets/user/login_bg_blue.png')
        },
        {
          title: '厂家学术推广费用透明化',
          desc: '杜绝临床一线代表摆拍、开假会',
          navImage: require('@/assets/user/login_slide_oringe.png'),
          bgImage: require('@/assets/user/login_bg_oringe.png')
        },
        {
          title: '医生大数据深度挖掘',
          desc: '多场景深层次沉淀数据，反馈优化学术产出',
          navImage: require('@/assets/user/login_slide_purple.png'),
          bgImage: require('@/assets/user/login_bg_purple.png')
        }
      ],
      swiperOptions: {
        effect: 'fade',
        speed: 2000,
        autoplay: true,
        loop: true
      },
      tabKey: isMobile() ? 'sms' : 'scan', // scan sms password
      isMounted: false,
      scanTimer: null, // 扫码登录查询结果定时器
      scanDialogVisible: false, // 扫码结果失败弹窗
      scanDialogMsg: '', // 扫码失败弹窗
      isReqScanResult: false, // 扫码结果查询限制
      rulePasswordForm: {
        account: '',
        password: '',
        remember: false
      }, //
      passwordRules: {
        account: [{ required: true, message: '请填写账号', trigger: 'blur' }],
        password: [{ required: true, message: '请填写密码', trigger: 'blur' }]
      },
      ruleSMSForm: {
        mobile: '',
        code: ''
      },
      smsRules: {
        mobile: [{ required: true, message: '请填写手机号', trigger: 'blur' }],
        code: [{ required: true, message: '请填写验证码', trigger: 'blur' }]
      },
      loading: false,
      passwordErrorModalMsg: '',
      passwordDialogVisible: false,
      forgetDialogVisible: false,
      forgetErrorModalMsg: '请用手机号登录后修改密码，或联系客服处理',
      isSend: false,
      isVoice: false,
      countDownTime: 0,
      showVoice: false,
      smsTimer: null,
      showFactoryModal: false,
      fullscreenLoading: false,
      copyBtn: null,
      isMobile: isMobile(),
      isLandscape: isLandscape(),
      screenHeight: ''
    }
  },
  computed: {
    ...mapGetters(['wxCodeImage', 'captchaAppId']),
    logo() {
      return this.isMobile
        ? require('@/assets/user/icon_logo_mobile.png')
        : require('@/assets/user/icon_logo.png')
    },
    isPhoneNumber() {
      return /^\d{11}$/gi.test(this.ruleSMSForm.mobile)
    },
    activeBarStyle() {
      if (!this.isMounted) return
      const offsetLeft = this.$refs[this.tabKey][0].offsetLeft
      const width = this.$refs[this.tabKey][0].offsetWidth
      return {
        width: `${width}px`,
        transform: `translateX(${offsetLeft}px)`
      }
    },
    tabList() {
      // 如果是移动端，只显示验证码登录和密码登录，否则显示扫码登录、验证码登录和密码登录
      return isMobile()
        ? [
          { name: '验证码登录', value: 'sms' },
          { name: '账号登录', value: 'password' }
        ]
        : [
          { name: '扫码登录', value: 'scan' },
          { name: '验证码登录', value: 'sms' },
          { name: '账号登录', value: 'password' }
        ]
    },
    loginClass() {
      return {
        'login-box': !this.isMobile,
        'login-box-mobile': this.isMobile && !this.isLandscape,
        'login-box-landscape': this.isMobile && this.isLandscape
      }
    }
  },
  beforeCreate() {
    // 引入腾讯云sdk
    initTCaptcha()
  },
  created() {
    const { t, t2, errorMsg, redirect } = this.$route.query

    if (errorMsg) {
      this.$message.error(decodeURIComponent(errorMsg))
      this.$router.replace({
        path: '/user/login',
        query: {
          redirect
        }
      })
    }
    if (t2 || t) {
      this.handleWxLogin(t, t2)
    }
  },
  mounted() {
    this.isMounted = true
    this.rulePasswordForm.account = window.localStorage.getItem('userAccount')
    this.rulePasswordForm.remember = Boolean(window.localStorage.getItem('isRemember'))
    if (this.tabKey === 'scan') {
      this.initScan()
    }
    this.screenHeight =
      document.documentElement.clientHeight ||
      document.body.clientHeight ||
      window.screen.availHeight // 处理安卓键盘弹起挤压页面的问题
  },
  methods: {
    ...mapActions([
      'userLogin',
      'userMobileLogin',
      'getSmsCode',
      'setNormalAccount',
      'checkCaptcha'
    ]),
    handleTabClick(tab) {
      if (tab === 'scan') {
        // this.initScan()
      } else {
        this.resetForm('ruleSMSForm')
        this.resetForm('rulePasswordForm')
      }

      this.tabKey = tab
    },
    initScan() {
      const { origin } = window.location
      const { VUE_APP_GATEWAY_QXS } = process.env
      const { redirect = '/' } = this.$route.query
      const styleHref = 'data:text/css;base64,Ci5pbXBvd2VyQm94IC50aXRsZSB7CiAgZGlzcGxheTogbm9uZTsKfQoKLmltcG93ZXJCb3ggLndycF9jb2RlIHsKICB3aWR0aDogMTgwcHg7CiAgbWFyZ2luOiAxMHB4IGF1dG8gMTBweCBhdXRvOwogIGZvbnQtc2l6ZTogMDsKICBiYWNrZ3JvdW5kOiAjZmZmZmZmOwogIGJveC1zaGFkb3c6IDBweCAxcHggN3B4IDBweCByZ2JhKDAsMCwwLDAuMTApOyAKfQoKLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgd2lkdGg6IDEwMCU7CiAgbWFyZ2luLXRvcDogMDsKICBib3JkZXI6IG5vbmU7Cn0KCi5pbXBvd2VyQm94IC5pbmZvIHsKICBkaXNwbGF5OiBub25lOwp9CgoucWxvZ2luX21vZCAgewogIG1hcmdpbjogMTBweCBhdXRvOwp9'
      let path = '/transfer'
      if (redirect) {
        path = `${path}?redirect=${redirect}`
      }
      new window.WxLogin({
        self_redirect: false,
        id: 'login_container',
        appid: 'wxcc773c2ea1083df6',
        scope: 'snsapi_login',
        /**
         * 因为微信授权登录的回调地址是固定的，
         * 所以只配了线上地址，由线上代码转发生成对应某个环境的 token
         */
        redirect_uri: encodeURIComponent(
          'https://factory.quxueshu.com/gateway/qxs-api/public/quick-login/callback'
        ),
        /**
         * 跟服务端约定
         * 第一个值: 当前页面地址
         * 第二个值: 代理地址
         * 第三个值: 登录成功后跳转的页面，服务端会带连个 token 回来
         *  - t 是 personToken
         *  - t2 是 orgLoginVo.token
         */
        state: `${origin},${VUE_APP_GATEWAY_QXS},${path}`,
        href: styleHref
      })
    },
    // 微信快速登录
    async handleWxLogin(personToken, token) {
      const orgLoginVo = {
        personToken,
        orgLoginVo: {
          token
        }
      }
      setPersonInfo(orgLoginVo)
      if (!token && personToken) {
        this.handleWxLoginSuccess({ personToken })
      }
    },
    handleWxLoginSuccess(data) {
      if (data.orgLoginVo && data.orgLoginVo.token) {
        // 登录成功会返回personToken 和 userinfo
        this.handleSuccessLogin(data)
      } else if (data.personToken) {
        this.personToken = data.personToken
        this.showFactoryModal = true
      }
    },
    handleSuccessLogin(res) {
      this.fullscreenLoading = true
      // 2.设置原工程的 userinfo => cookie , username => locolstorage，并且 window.location.href = `老工程+/index`
      // personToken会有没有 就是这个人账号登录 没有绑定手机
      setPersonInfo(res)
      // 所有登录统一成功操作 设置缓存 人机验证 路由跳转等
      this.$store.commit('SET_USER_INFO', res.orgLoginVo)

      this.fullscreenLoading = false
      const { redirect = '/' } = this.$route.query
      // 跳转
      this.$router.replace(redirect)
    },
    closeScanErrorModal() {
      this.scanDialogVisible = false
      this.tabKey = 'password'
    },
    closePasswordErrorModal() {
      this.passwordDialogVisible = false
    },
    closeForgetErrorModal() {
      this.forgetDialogVisible = false
      this.tabKey = 'sms'
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (formName === 'rulePasswordForm') {
            if (window.sessionStorage.getItem('passwordError') > 1) {
              // 错误之后 每次都要人机验证
              this.createCaptcha(this.handlePasswordSubmit)
              return
            }
            this.handlePasswordSubmit()
          } else if (formName === 'ruleSMSForm') {
            this.handleSmsSubmit()
          }
        } else {
          return false
        }
      })
    },
    handlePasswordSubmit() {
      // 账号密码登录
      if (this.loading) return
      this.loading = true
      const req = {
        login: this.rulePasswordForm.account,
        password: cryptoEncrypt(this.rulePasswordForm.password)
      }
      this.userLogin(req).then(
        res => {
          window.sessionStorage.removeItem('passwordError')
          // 记住密码和移除密码
          if (this.rulePasswordForm.remember) {
            window.localStorage.setItem('userAccount', this.rulePasswordForm.account)
            window.localStorage.setItem('isRemember', this.rulePasswordForm.remember)
          } else {
            window.localStorage.removeItem('userAccount')
            window.localStorage.removeItem('isRemember', this.rulePasswordForm.remember)
          }
          this.loading = false
          if (res.orgLoginVo) {
            // 登录成功会返回personToken 和 userinfo
            this.handleSuccessLogin(res)
          } else if (res.personToken) {
            this.personToken = res.personToken
            this.showFactoryModal = true
          }
        },
        rea => {
          this.loading = false
          if (rea.code === 4118) {
            let errorCount = window.sessionStorage.getItem('passwordError') || 0
            errorCount++
            window.sessionStorage.setItem('passwordError', errorCount)
            this.$message.error(rea.message)
          } else if (rea.code === 4000) {
            // 人机验证  返回错误码 一个月内首次登录和更换设备ip需要人机验证
            this.createCaptcha(this.handlePasswordSubmit)
          } else if (rea.code === 4119) {
            // 五次输错code码判断弹窗
            this.passwordDialogVisible = true
            this.passwordErrorModalMsg = rea.message
          } else {
            this.$message.error(rea.message)
          }
        }
      )
    },
    handleSmsSubmit() {
      // 手机号验证码登录
      if (this.loading) return
      this.loading = true
      const req = {
        mobile: this.ruleSMSForm.mobile,
        smsCaptcha: this.ruleSMSForm.code,
        clientType: 3 // 医生端：1； 直播推流端：101； 代表端：2；  厂家端：3； 患者端：4；
      }
      this.userMobileLogin(req).then(
        res => {
          this.loading = false
          if (res.orgLoginVo) {
            // 登录成功会返回personToken 和 userinfo
            this.handleSuccessLogin(res)
          } else if (res.personToken) {
            // 没有userinfo 有token 选择账号列表
            this.personToken = res.personToken
            this.showFactoryModal = true
          }
        },
        rea => {
          this.loading = false
          // 未绑定账号code码判断弹窗
          if (rea.code === 4015) {
            this.passwordDialogVisible = true
            this.passwordErrorModalMsg = '当前手机号尚未绑定任何账号，请用其他方式登录'
          } else {
            this.$message.error(rea.message)
          }
        }
      )
    },
    onJumpForget() {
      this.forgetDialogVisible = true
    },
    sendSmsPre() {
      // 发送验证码前 前端不做人机校验 通过code===4000来显示人机校验
      if (!this.ruleSMSForm.mobile) return this.$message.error('请输入手机号')
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      this.createCaptcha(this.sendSms)
    },
    sendSms(data) {
      this.isVoice = false
      this.getVerifyCode(data)
    },
    sendVoicePre() {
      // 发送验证码前 前端不做人机校验 通过code===4000来显示人机校验
      if (!this.ruleSMSForm.mobile) return this.$message.error('请输入手机号')
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      this.createCaptcha(this.sendVoice)
    },
    sendVoice(data) {
      if (this.isVoice) return
      this.isVoice = true
      this.clearSmsTimer()
      this.getVerifyCode(data)
    },
    getVerifyCode() {
      if (this.countDownTime) return
      if (!this.ruleSMSForm.mobile) {
        return this.$message.error('请输入手机号')
      }
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.isSend) {
        return
      }
      this.isSend = true
      const req = {
        mobile: this.ruleSMSForm.mobile,
        clientType: 30, // 1 医生端 2 代表端 30 厂家组织核心
        smsType: 105, // 101 注册验证码 102 重置密码 103 更换手机号 104 推流端验证码 105厂家端登录验证码 106厂家端绑定手机获取验证码 107找回密码获取验证码
        isVoice: this.isVoice
      }
      this.getSmsCode(req)
        .then(() => {
          this.$message.success('验证码已发送')
          this.isSend = false
          this.doIntervalCount()
        })
        .catch(rea => {
          if (rea.code === 4000) {
            const func = this.isVoice ? this.sendVoice : this.sendSms
            this.isSend = false
            this.isVoice = false
            this.createCaptcha(func)
          } else {
            this.isSend = false
            this.isVoice = false
            this.$message.error(rea.message)
          }
        })
    },
    doIntervalCount() {
      this.countDownTime = 60
      this.smsTimer = setInterval(() => {
        this.countDownTime--
        if (this.countDownTime < 30) {
          this.showVoice = true
        }
        if (this.countDownTime < 1) {
          this.isVoice = false
          this.clearSmsTimer()
        }
      }, 1000)
    },
    clearSmsTimer() {
      this.countDownTime = 0
      this.smsTimer && clearInterval(this.smsTimer)
      this.smsTimer = null
    },
    onCloseModal() {
      this.showFactoryModal = false
    },
    onCheckAccount(item) {
      const req = {
        userId: item.id,
        personToken: this.personToken
      }
      this.setNormalAccount(req).then(
        res => {
          // 设置默认厂家之后的操作
          this.handleSuccessLogin(res)
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    // 创建腾讯云验证 传入回调
    createCaptcha(cb, faii) {
      const captcha1 = new window.TencentCaptcha(this.captchaAppId, res => {
        if (res.ret === 0) {
          // 成功 处理回调
          this.onCheckCaptcha(res).then(() => {
            cb && cb(res)
          })
        } else if (res.ret === 2) {
          // 失败
          faii && faii()
        }
      })
      captcha1.show()
    },
    onCheckCaptcha(data) {
      const req = {
        randStr: data.randstr,
        ticket: data.ticket
      }
      if (this.tabKey === 'sms') {
        req.mobile = this.ruleSMSForm.mobile
      }
      if (this.tabKey === 'password') {
        req.login = this.rulePasswordForm.account
      }
      return new Promise((resolve, reject) => {
        this.checkCaptcha(req).then(
          () => {
            resolve()
          },
          rea => {
            reject()
            this.$message.error(rea.message)
          }
        )
      })
    },
    testNumber(e, val) {
      this.ruleSMSForm[val] = e.replace(/[^\d]/g, '')
    },
    resetForm(formName) {
      this.$refs[formName] && this.$refs[formName].resetFields()
    },
    onJumpDownApp() {
      window.location.href = 'https://d.quxueshu.com'
    }
  }
}
</script>
<style lang="scss" scoped>
$color-primary: #3d61e3;
$color-assist: #b6bbc7;
$colo-border: #e9eaeb;
.loginBody {
  position: relative;
  display: flex;
  height: 100%;
  background: linear-gradient(180deg, #3d61e3 20%, #fcfdff 40%, #fff 100%);
}
.explain {
  background: $color-primary !important;
}
.slide-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1000px;
  height: 560px;
  transform: translate(-50%, -50%);
}
.bg-image {
  width: 100%;
  height: 100%;
}
.text-content {
  width: 46%;
  text-align: center;
  padding-right: 40px;
}
.text-title {
  font-size: 24px;
  color: #fff;
  padding-top: 15px;
  font-weight: bold;
}
.text-desc {
  font-size: 14px;
  color: #fff;
  padding-bottom: 20px;
}
.logo {
  position: relative;
  z-index: 1200;
  height: 40px;
  padding-left: 55px;
  padding-top: 55px;
  width: 100%;
  .icon-logo {
    height: 60px;
  }
  @media screen and (max-width: 768px) {
    // 水平局中显示
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-left: 0;
    .icon-logo {
      height: 40px;
    }
  }
}
.swiper-reset {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.login-box,
.login-box-mobile,
.login-box-landscape {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
}
.login-box {
  width: 480px;
  height: 480px;
  transform: translate(-40px, -50%);
  .tab-list {
    height: 70px;
    padding: 0 30px;
    border-bottom: 1px solid $colo-border;
    position: relative;
  }
}
.login-box-mobile {
  width: 80%;
  height: 400px;
  // 水平居中显示
  transform: translate(-50%, -60%);
  .tab-list {
    height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid $colo-border;
    position: relative;
  }
}
.login-box-landscape {
  width: 40%;
  height: 300px;
  // 水平居中显示
  transform: translate(-50%, -50%);
  .tab-list {
    height: 40px;
    padding: 0 20px;
    border-bottom: 1px solid $colo-border;
    position: relative;
  }
}
.tab-item {
  color: $color-assist;
  font-size: 18px;
  &.active {
    color: $color-primary;
  }
}
.active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background: $color-primary;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.scan-image {
  overflow: hidden;
  ::v-deep iframe {
    width: 200px;
    height: 230px;
  }
}
.scan-box {
  padding-top: 35px;
  padding-bottom: 60px;
}
.scan-icon-box {
  margin-top: 8px;
  color: $color-assist;
  margin-bottom: 15px;
}
.scan-icon {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.scan-icon-item {
  margin-right: 26px;
}
.color-b6 {
  color: $color-assist;
}
.margin-t25 {
  margin-top: 25px;
}
.password-box,
.sms-box {
  padding: 50px 70px;
  @media screen and (max-width: 768px) {
    padding: 30px;
  }
  @media screen and (max-width: 1000px) {
    padding: 10px 20px;
  }
}
.password-box >>> .el-form-item__label,
.sms-box >>> .el-form-item__label {
  font-size: 14px;
  color: #999eab;
}
.password-box ::v-deep .el-input__inner,
.sms-box ::v-deep .el-input__inner {
  border: 0;
  border-bottom: 1px solid $colo-border;
  border-radius: 0;
  padding-left: 0;
  color: #272e40;
  font-size: 14px;
}
.password-box ::v-deep .el-input__inner:focus,
.sms-box ::v-deep .el-input__inner:focus {
  border-color: $color-primary;
}
.password-box ::v-deep .el-form--label-top .el-form-item__label,
.sms-box ::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
  line-height: unset;
}
.password-box ::v-deep .el-form-item__error,
.sms-box ::v-deep .el-form-item__error {
  font-size: 14px;
}
.password-box ::v-deep .remember-level .el-form-item__content,
.sms-box ::v-deep .remember-level .el-form-item__content {
  display: flex;
  justify-content: space-between;
  color: $color-assist;
  position: relative;
  &::after,
  &::before {
    display: none;
  }
}
.password-box ::v-deep .el-checkbox {
  .el-checkbox__label {
    color: $color-assist;
  }
}
.password-box ::v-deep .el-button--primary,
.sms-box ::v-deep .el-button--primary {
  width: 100%;
  height: 50px;
  font-size: 18px;
}
.send-sms-btn {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 0;
  &.disable {
    color: $color-assist;
    cursor: default;
  }
}
.sms-login-btn {
  padding-top: 60px;
}
.sms-voice-btn {
  text-align: right;
}
.dialog-box ::v-deep .el-dialog__header {
  display: none;
}
.icon-item {
  height: 40px;
  padding: 0 25px;
  border-radius: 24px;
  margin-right: 55px;
  color: white;
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  img {
    margin-right: 10px;
  }
}
.display-input {
  width: 0;
  height: 0;
  border: 0;
  display: block;
}
</style>
