<template>
  <div />
</template>

<script>
import qs from 'qs'
import { setPersonInfo } from '@/utils/auth'
import $API from '@/apis/api-types'
import $axios from '@/utils/request'

const { t, t2 } = qs.parse(location.search, { ignoreQueryPrefix: true })

const data = new Map()
async function getUserInfo(token) {
  if (data.has(token)) {
    return data.get(token) instanceof Promise ? await data.get(token) : {data: data.get(token)}
  } else {
    const promise = $axios({
      url: $API.getLoginAuthInfo,
      method: 'get',
      headers: {
        token
      }
    })
    data.set(token, promise) // 存储这个promise
    return promise
  }
}
// 获取 t2 参数
if (t) {
  setPersonInfo({
    orgLoginVo: {
      token: t2
    },
    personToken: t
  })
}
if (t2) {
  getUserInfo(t2)
}

export default {
  name: 'Transfer',
  async beforeRouteEnter(to, from, next) {
    const { redirect, t2, errorMsg } = to.query
    if (errorMsg || !t2) {
      next({
        path: '/user/login',
        query: {
          ...to.query
        }
      })
    } else {
      try {
        const { data } = await getUserInfo(t2)
        setPersonInfo(data)
        next(vm => {
          vm.$store.commit('SET_USER_INFO', data.orgLoginVo)
          setTimeout(() => {
            vm.$router.replace(redirect)
          })
        })
      } catch (error) {
        next()
      }
    }
  }
}
</script>
